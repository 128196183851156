gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

if (ScrollTrigger.isTouch !== 1) {
    // ScrollSmoother.create({
    //     wrapper: '.wrapper-smooth',
    //     content: '.contern-smooth',
    //     effects: true
    // })

    gsap.fromTo('.js-scale-animate', { scale: 0.75, y: 100 }, {
        scale: 1, y: 0,
        scrollTrigger: {
            trigger: '.js-scale-animate',
            start: '-1000',
            end: '-300',
            scrub: true
        }
    })

    gsap.fromTo('.js-scale-animate-2', { scale: 0.75, y: 100 }, {
        scale: 1, y: 0,
        scrollTrigger: {
            trigger: '.js-scale-animate-2',
            start: '-1000',
            end: '-500',
            scrub: true
        }
    })
}