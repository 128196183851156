const animateValue = (
    obj: Element,
    start: number,
    end: number,
    duration: number,
) => {
    let startTimestamp = null;
    const step = (timestamp) => {
        if (!startTimestamp) startTimestamp = timestamp;
        const progress = Math.min((timestamp - startTimestamp) / duration, 1);
        const i = new Intl.NumberFormat('ru-RU').format(
            Math.floor(progress * (end - start) + start),
        );
        obj.innerHTML = i;
        if (progress < 1) {
            window.requestAnimationFrame(step);
        }
    };
    window.requestAnimationFrame(step);
};

document.addEventListener('DOMContentLoaded', () => {
    const observerCallback = (entries: any, observer: any) => {
        entries.forEach((entry: any) => {
            if (
                entry.isIntersecting &&
                !entry.target.classList.contains('active')
            ) {
                const item = entry.target;
                const res = Number(item.innerHTML.replace(/\D/g, ''));
                animateValue(item, 0, res, 1000);
                item.classList.add('active');
            }
        });
    };

    const observerOptions = {
        root: null,
        rootMargin: '0px',
        threshold: 0,
    };

    const observer = new IntersectionObserver(
        observerCallback,
        observerOptions,
    );

    const counters = document.querySelectorAll('.js-counter');
    counters.forEach((item) => {
        observer.observe(item);
    });
});
