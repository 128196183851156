import GLightbox from 'glightbox';

const lightBoxInit = () => {
    const lightbox = GLightbox({
        touchNavigation: true,
        loop: true,
        autoplayVideos: true,
    });
}

document.addEventListener('DOMContentLoaded', () => {
    lightBoxInit();
});

document.addEventListener('AjaxContentLoaded', () => {
    lightBoxInit();
});