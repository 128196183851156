document.addEventListener(
    'DOMContentLoaded',
    () => {
        const readMore = () => {
            const showMoreButtons = document.querySelectorAll('.js-read-more');
            showMoreButtons.forEach((more) => {

                const textContent = more.closest('.read-block__item')?.querySelector('.read-block__text');
                function countLines(element) {
                    const style = window.getComputedStyle(element);
                    const lineHeight = parseFloat(style.lineHeight);
                    const height = parseFloat(style.height);
                    const lines = Math.round(height / lineHeight);
                    return lines;
                }
                const numberOfLines = countLines(textContent);

                if (textContent.scrollHeight > 105 && numberOfLines > 5) {
                    textContent?.classList.add('hidden-text');
                }
                more?.addEventListener('click', (e) => {
                    e.preventDefault();
                    if (e.target.closest('.js-read-more') || e.target.classList.contains('js-read-more')) {

                        if (textContent.classList.contains('hidden-text')) {
                            textContent.style.maxHeight = textContent?.scrollHeight + 'px';
                            textContent?.classList.remove('hidden-text');
                            more.classList.add('hide');
                        }
                    }
                });
            })
        }
        readMore();
        const textMore = () => {
            const showMoreText = document.querySelectorAll('.js-text-more');

            showMoreText.forEach((more) => {
                const textContent = more.closest('.reviews__text')?.querySelector('.reviews__block__text');

                function getActualLineCount(element) {
                    const lineHeight = parseFloat(window.getComputedStyle(element).lineHeight);
                    const totalHeight = element.scrollHeight;

                    return Math.round(totalHeight / lineHeight);
                }

                setTimeout(() => {
                    const actualLineCount = getActualLineCount(textContent);
                    if (actualLineCount <= 5) {
                        more?.classList.add('hide');
                    } else {
                        more?.classList.remove('hide');
                    }
                }, 500);

                window.addEventListener('resize', () => {
                    const actualLineCount = getActualLineCount(textContent);
                    if (actualLineCount <= 5) {
                        more?.classList.add('hide');
                    } else {
                        more?.classList.remove('hide');
                    }
                });

                more.addEventListener('click', (e) => {
                    e.preventDefault();

                    if (!textContent?.classList.contains('show')) {
                        textContent?.classList.add('show');
                        more.textContent = 'Скрыть отзыв';
                    } else {
                        textContent?.classList.remove('show');
                        more.textContent = 'Читать далее';
                    }

                });
            });
        }
        textMore();
        window.addEventListener('resize', () => {
            readMore();
        });
    },
    { once: true },
);
