import { slideToggle } from '@/js/components/animation/toggle';
document.addEventListener(
    'DOMContentLoaded',
    () => {
        const { body } = document;
        const jsMenuBurger = document.querySelector('.js-menu-burger');
        const jsCloseMenu = document.querySelector('.js-close-menu');
        const jsHeaderAnimate = document.querySelector('.js-header-animate');
        let winTop = 0;

        jsMenuBurger?.addEventListener('click', (e) => {
            e.preventDefault();
            if (!body.classList.contains('menu-show')) {
                winTop = window.scrollY;
                body.classList.add('menu-show');
                body.style.top = `-${winTop}px`;
                body.style.setProperty('--wintop', `${winTop}px`);
            }
        });
        jsCloseMenu?.addEventListener('click', (e) => {
            e.preventDefault();
            if (body.classList.contains('menu-show')) {
                body.classList.remove('menu-show');
                document
                    .querySelector('html')
                    ?.classList.add('scroll-smooth-disabled');
                window.scroll(0, winTop);
                document
                    .querySelector('html')
                    ?.classList.remove('scroll-smooth-disabled');
                body.style.removeProperty('top');
                body.style.removeProperty('--wintop');
            }
        });

        const hoverMenu = () => {
            const jsMenuItemDrop = jsHeaderAnimate?.querySelectorAll('.js-sublist');
            jsMenuItemDrop?.forEach((item) => {

                if (document.documentElement.clientWidth > 1280) {
                    item.addEventListener('mouseenter', () => {
                        jsHeaderAnimate?.classList.add('color-bg');
                    });
                    item.addEventListener('mouseleave', () => {
                        jsHeaderAnimate?.classList.remove('color-bg');
                    });
                }
            });
        }
        hoverMenu();

        window.addEventListener('resize', () => {
            hoverMenu();
        });


        const links = document.querySelectorAll('.js-menu-toggle');
        links.forEach((link) => {
            link.addEventListener('click', (e) => {
                if (window.matchMedia('(max-width: 1279px)').matches) {
                    e.preventDefault();
                    const target = link?.nextElementSibling;
                    slideToggle(link, target, 300);
                }
            });
        });
    },
    { once: true },
);
