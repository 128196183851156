document.addEventListener(
    'DOMContentLoaded',
    () => {

        const headerSearch = () => {
            const jsSearchOpen = document.querySelector('.js-search-open');
            const jsSearchClose = document.querySelector('.js-search-close');
            const input = jsSearchOpen?.closest('.header-search')?.querySelector('input');

            /* открытие поиска на десктопе */


            jsSearchOpen?.addEventListener('click', (e) => {
                e.preventDefault();
                jsSearchOpen.closest('.header-search')?.classList.add('open');
                input?.focus();

            });

            /* проверка на события внутри поля */

            function logEvent() {
                if (input.value.length < 1) {
                    jsSearchClose?.classList.add('hide');
                } else {
                    jsSearchClose?.classList.remove('hide');
                }
            }

            input?.addEventListener('input', logEvent);
            input?.addEventListener('change', logEvent);
            input?.addEventListener('focus', logEvent);
            input?.addEventListener('blur', logEvent);
            input?.addEventListener('keydown', logEvent);
            input?.addEventListener('keyup', logEvent);
            input?.addEventListener('keypress', logEvent);
            input?.addEventListener('click', logEvent);
            input?.addEventListener('dblclick', logEvent);

            /* запрет на поиск внутри пустого поля */

            const form = input?.closest('form');
            input?.addEventListener('focus', (e) => {
                form?.classList.add('search--focus');
                if (input.value.length < 1 && e.key == 'Enter') {
                    e.preventDefault();
                }
            });
            input?.addEventListener('blur', () => {
                form?.classList.remove('search--focus');
            });
            input?.addEventListener('keypress', (e) => {
                if (input.value.length < 1 && e.key == 'Enter') {
                    e.preventDefault();
                }
            });

            /* закрытие поиска надесктопе по клику кнопки, esc, по клику вне */

            jsSearchClose?.addEventListener('click', (e) => {
                e.preventDefault();
                input.value = '';
                jsSearchClose.closest('.header-search')?.classList.remove('open');
            });
            document.addEventListener('keydown', (e) => {
                if (e.key === 'Escape') {
                    input.value = '';
                    jsSearchClose.closest('.header-search')?.classList.remove('open');
                }
            });
            document.addEventListener('click', (e) => {
                if (!jsSearchClose.closest('.header-search').contains(e.target) && !jsSearchClose.closest('.header-search').contains(e.target)) {
                    input.value = '';
                    jsSearchClose.closest('.header-search')?.classList.remove('open');
                }
            });
        }
        headerSearch();
    },
    { once: true },
);
