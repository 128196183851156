import { closeModal } from '@/js/components/modals/utils';
import { delay } from '@/js/utils/delay';

document.addEventListener(
    'DOMContentLoaded',
    () => {
        document.addEventListener('bouncerFormValid', (e) => {
            const form = e.target as HTMLFormElement | null;
            if (form === null || !form.classList.contains('js-form-action')) {
                return;
            }
            const { method } = form;
            const { action } = form;
            const formInputs = form.querySelectorAll<HTMLInputElement>(
                'select, input, textarea',
            );

            const button = form.querySelector<HTMLButtonElement>(
                'button[type="submit"]',
            );
            if (button !== null) {
                button.setAttribute('disabled', 'disabled');
            }

            const formId = form.getAttribute('data-form-id');
            const messageSuccess = document.querySelector(
                '.form-message-success[data-form-id="' + formId + '"]',
            );
            const messageError = document.querySelector(
                '.form-message-error[data-form-id="' + formId + '"]',
            );

            const successCallback = () => {
                form.reset();
                formInputs.forEach((input) => {
                    input.dispatchEvent(new Event('change'));
                });
                form.classList.add('hide');
                messageSuccess?.classList.remove('hide');
                window.dispatchEvent(new Event('resize'));
            };
            const errorCallback = () => {
                form.classList.add('hide');
                messageError?.classList.remove('hide');
                window.dispatchEvent(new Event('resize'));
            };

            const sendForm = async () => {
                try {
                    const body = new FormData(form);
                    const response = await fetch(action, {
                        method,
                        headers: {
                            Accept: 'application/json',
                        },
                        body,
                    });
                    if (button) {
                        button.removeAttribute('disabled');
                    }
                    if (response.ok) {
                        successCallback();
                        return await Promise.resolve();
                    }
                    errorCallback();
                    throw new Error('Error form');
                } catch (err) {
                    if (button) {
                        button.removeAttribute('disabled');
                    }
                    errorCallback();
                }
            };
            sendForm();
        });

        const formShowLinks = document.querySelectorAll('.js-form-show');
        formShowLinks.forEach((item) => {
            item.addEventListener('click', async (e) => {
                e.preventDefault();

                if (
                    item.closest('.modal') &&
                    item.closest('.form-message-success')
                ) {
                    closeModal();
                    await delay(300);
                }

                const wrapper = item.closest('[data-form-id]');
                const formId = wrapper?.getAttribute('data-form-id');
                wrapper?.classList.add('hide');
                const form = document.querySelector(
                    '.form[data-form-id="' + formId + '"]',
                );
                form?.classList.remove('hide');
                window.dispatchEvent(new Event('resize'));
            });
        });
    },
    { once: true },
);
