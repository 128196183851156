const observerCallback = (entries: any, observer: any) => {
    entries.forEach((entry: any) => {
        if (entry.isIntersecting) {
            const image = entry.target;

            if (image.getAttribute('data-src')) {
                const src = image.getAttribute('data-src');
                image.src = src;

                const img = new Image();
                img.src = src;

                img.addEventListener('load', () => {
                    image.classList.add('img-loaded');
                });
            } else if (image.getAttribute('data-src-bg')) {
                const src = image.getAttribute('data-src-bg');
                image.style.backgroundImage = `url('${src}')`;

                const img = new Image();
                img.src = src;

                img.addEventListener('load', () => {
                    image.classList.add('img-loaded');
                });
            } else {
                image.classList.add('active');
            }
        }
    });
};

const observerOptions = {
    root: null,
    rootMargin: '0px',
    threshold: 0,
};

const observer = new IntersectionObserver(observerCallback, observerOptions);

const imagesLazyLoader = () => {
    const images = document.querySelectorAll(
        '[data-src],[data-src-bg],.page-in',
    );
    images.forEach((el) => observer.observe(el));
};

document.addEventListener(
    'DOMContentLoaded',
    () => {
        imagesLazyLoader();
    },
    { once: true },
);

// Событие с бэка, например после аякс загрузки контента
document.addEventListener('AjaxContentLoaded', () => {
    imagesLazyLoader();
});
