import MicroModal from 'micromodal';
import defaultSettings from './settings';
import { openModal } from './utils';

MicroModal.modalSettings = defaultSettings;

const fModalPosition = () => {
    const modals = document.querySelectorAll(
        '.modal.is-open .modal__container',
    );
    modals.forEach((modal) => {
        const modalParent = modal.closest('.modal__overlay');
        if (typeof modalParent !== 'undefined' && modalParent != null) {
            if (modal.clientHeight > modalParent.clientHeight) {
                modalParent.classList.add('modal__overlay--block');
            } else {
                modalParent.classList.remove('modal__overlay--block');
            }
        }
    });
};

const scrollWidth = () => {
    if (!document.body.classList.contains('modal-show')) {
        let scrollWidth = 0;
        scrollWidth = window.innerWidth - document.documentElement.clientWidth;
        document.body.style.setProperty('--scroll-width', `${scrollWidth}px`);
    }
};

document.addEventListener(
    'DOMContentLoaded',
    () => {
        scrollWidth();
        MicroModal.init(MicroModal.modalSettings);
        const modallinks = document.querySelectorAll('[data-micromodal]');
        modallinks.forEach((item, index) => {
            item.addEventListener('click', (e) => {
                e.preventDefault();
                const modalID = item.getAttribute('data-micromodal') as string;
                if (modalID !== null && modalID !== undefined) {
                    openModal(modalID);
                    setTimeout(() => {
                        fModalPosition();
                    }, 100);
                }
            });
        });
    },
    { once: true },
);

window.addEventListener('resize', () => {
    scrollWidth();
    fModalPosition();
});
